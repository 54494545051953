<template>
  <div class="jiedu">
    <div v-if="hasJiedu">
      <vipPermission v-if="showVipCom" :vipPermissionVisible="true" :type="2"></vipPermission>
      <div
        style="margin-bottom: 19px; font-weight: bold; font-size: 18px"
      ></div>
      <jieduChoose v-model="input" :list="list" />
      <div class="content" :style="showVipCom?'min-height: 90vh;':''">
        <div class="jietu2" style="height: 120px">
          <div class="title">
            <div>
              <div
                onmouseover="this.style.color = '#156ED0'"
                onmouseout="this.style.color = 'black'"
                @click="handleCopy"
                class="copy-sub"
              >
                {{ tableData.subtitle }}
              </div>
            </div>
            <!-- <div
              @click="handlePlan"
              :class="included == '已纳入' ? 'include' : 'notInclude'"
            >
              {{ included }}
            </div> -->
          </div>
          <div>
            <span
              v-for="item in supportList"
              :key="item"
              class="btcolor"
              :style="{
                background:
                  item == '资金扶持'
                    ? '#FF7200'
                    : item == '其他'
                    ? 'rgba(0, 0, 0, 0.4)'
                    : item == '称号认定'
                    ? '#1FAA53'
                    : item == '股权投资'
                    ? '#1F88F9'
                    : item == '减税降费'
                    ? '#BF382F'
                    : item == '金融扶持'
                    ? '#6CC2ED'
                    : '',
              }"
            >
              {{ item ? item : "无称号" }}
            </span>
            <span style="color: #9e9e9e" v-if="tableData.money > 0"
              >最高补贴：{{ tableData.money }}万</span
            >
            <span
              class="jiezhi"
              :style="
                tableData.declareDate >= 0 ? 'color: #FF0000' : 'color:gray'
              "
              >{{
                tableData.daysRemaining > 0
                  ? "剩 " + tableData.daysRemaining + " 天"
                  : tableData.daysRemaining == 0 
                  ? "今天截止"
                  : "已截止"
              }}</span
            >
          </div>
        </div>
        <!-- <div style="position: relative;height: 100%;"> -->
          
          <div class="tit">扶持标准</div>
          <div
            style="color: #585858; font-size: 16px"
            v-html="tableData.subtitleDescribe"
          ></div>
          <div class="tit">申报条件</div>
          <el-table
            ref="multipleTable"
            :class="{ canUserSelect: canSelect }"
            border
            :data="tableData.declarationConditions"
            style="width: 100%"
            :header-cell-style="{ background: '#F1F1F1' }"
          >
            <el-table-column
              type="index"
              label="序号"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column label="申报条件概述" align="center">
              <template slot-scope="scope">
                <div
                  style="text-align: left; white-space: pre-wrap"
                  v-html="scope.row"
                ></div>
              </template>
            </el-table-column>
          </el-table>
          <div class="tit">材料清单</div>
          <el-table
            ref="multipleTable"
            :class="{ canUserSelect: canSelect }"
            border
            :data="tableData.cailiaoqingdan"
            style="width: 100%"
            :header-cell-style="{ background: '#F1F1F1' }"
          >
            <el-table-column
              type="index"
              label="序号"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column label="材料简称" width="250" align="center">
              <template slot-scope="scope">
                <div style="text-align: left; white-space: normal">
                  {{ scope.row.title }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="说明" align="center">
              <template slot-scope="scope">
                <div style="text-align: left" v-html="scope.row.content"></div>
              </template>
            </el-table-column>
          </el-table>
        <!-- </div> -->
      </div>
    </div>
    <div v-else>暂无政策解读</div>
    <permission
      v-if="dialogVisiblePermission"
      :dialogVisiblePermission="dialogVisiblePermission"
      @handleToggle="handleToggle"
      :typeDialog="typeDialog"
      @handlePermission="handlePermission"
    >
    </permission>
    <el-dialog
      :visible.sync="visiblePlan"
      width="20%"
      height="20%"
      class="dialog_content"
    >
      <div style="text-align: center">
        <img src="@/assets/pc/images/public/includ-success.png" alt="" />
      </div>
      <div
        style="text-align: center; margin-top: 16px; margin-bottom: 5px"
        class="dialog_text_color"
      >
        纳入计划成功
      </div>
      <div style="text-align: center" class="dialog_text_color">
        您可到申报管家-申报计划中查看
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visiblePlan = false">我知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import jieduChoose from "./components/jieduChoose";
import { request } from "@network";
import dayjs from "dayjs";
import { setInfoData, getVip } from "@/assets/public/utils/token";
import permission from "@/components/pc/permission.vue";
import { mapState } from "vuex";
export default {
  props: {
    // routeId: {
    //   type: Number
    // }
  },
  components: {
    jieduChoose,
    permission,
  },
  data() {
    return {
      list: [],
      input: "1",
      tableData: [],
      tags: [],
      hasJiedu: true,
      time: "",
      included: "+纳入计划",
      tss: [
        { title: "jj", content: "称号认定" },
        { title: "jon", content: "资金扶持" },
        { title: "jay", content: "金融扶持" },
        { title: "james", content: "股权投资" },
        { title: "jameslate", content: "减税降费" },
      ],
      dialogVisible: false,
      dialogVisiblePermission: false,
      typeDialog: "Vip",
      visiblePlan: false,
      supportList: [],
    };
  },
  created() {
    // this.getUserDeclarePlan();
  },
  mounted() {
    this.getPolicyInterpretation().then(() => {
      if(this.showVipCom)return
      this.getProjectDetails();
    });
  },
  computed: {
    // activation() {
    //   return (icontent) => { // 使用JavaScript闭包，进行传值操作
    //     if (icontent === "资金扶持") {
    //       return { 'background': '#fb9435' }
    //     }
    //     else if (icontent === " 称号认定") {
    //       return { 'background': '#2EBC66' }
    //     }
    //     else if (icontent === "股权投资") {
    //       return { 'background': '#1F88F9' }
    //     } else if (icontent === "减税降费") {
    //       return { 'background': '#BF382F' }
    //     } else if (icontent === "金融扶持") {
    //       return { 'background': '#6CC2ED' }
    //     }

    //   }
    // },
    // ...mapState("policy", ["preview"]),
    ...mapState({
      preview: state => state.policy.preview,
      isVip: (state) => state.login.isVip
    }),
    canSelect() {
      return this.$route.query.preview || this.preview;
    },
    getFinDay() {
      const declareDate = dayjs(this.tableData.declareDate);
      const finDay = declareDate.diff(dayjs().format("YYYY-MM-DD"), "day");
      return finDay;
      // return declareDate
    },
    showVipCom () {
      return this.isVip === '普通用户';
    }
  },
  methods: {
    getDataTags() {
      const data = this.tableData.supportMode;
      let newArr = [];
      if (data) {
        const strLe = data.length;
        const oldArr = data.substring(1, strLe - 1);
        newArr = oldArr.split(",");
      }
      return newArr;
    },
    async getPolicyInterpretation() {
      let preview = "";
      preview =
        this.$store.state.policy.preview || this.$route.query.preview
          ? "1"
          : "";
      const { data: res } = await request({
        method: "POST",
        url: "/pcp/policyDetail/getPolicyInterpretation",
        data: {
          // id: this.routeId,
          id: this.$route.query.id,
          page: 0,
          pageSize: 0,
          preview: preview,
        },
      });
      if (res.code !== 200) {
        this.hasJiedu = false;
        return;
      }
      this.hasJiedu = true;
      this.list = res.data.filter((item) => {
        return item.subtitle !== "";
      });
      if (!this.$route.query.inputId) {
        this.input = "" + this.list[0].id;
      } else {
        this.input = "" + this.$route.query.inputId;
      }
      return Promise.resolve();
    },
    async getProjectDetails() {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/policyDetail/getProjectDetails",
        params: {
          projectId: parseInt(this.input),
        },
      });
      if (res.code !== 200) {
        this.hasJiedu = false;
        return;
      }
      this.hasJiedu = true;
      res.data.cailiaoqingdan.forEach((item) => {
        item.content = item.content.replace(/\n/g, "<br/>");
      });
      this.tableData = res.data;
      this.tags = this.tableData.supportMode;
      this.supportList = res.data.supportMode;
    },
    async getUserDeclarePlan() {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/declaremanager/getUserDeclarePlan",
        params: {
          memberId: JSON.parse(localStorage.getItem("userinfo")).id,
          formalId: parseInt(this.input),
        },
      });
      if (res.code === 200) {
        this.included = res.data.length > 0 ? "已纳入" : "+纳入计划";
      }
    },
    handlePlan() {
      const that = this;
      setInfoData(request).then((res) => {
        if (res === "islogin") {
          var str = this.included === "已纳入" ? "1" : "0";
          request({
            method: "POST",
            url: "/pcp/declaremanager/addDeclarePlan",
            data: {
              memberId: JSON.parse(localStorage.getItem("userinfo")).id,
              formalId: parseInt(this.input),
              type: str,
            },
          })
            .then((res) => {
              getVip(request).then((resVip) => {
                if (resVip === "普通用户") {
                  that.dialogVisiblePermission = !that.dialogVisiblePermission;
                  that.typeDialog = "Vip";
                  // this.$message.error('您还不是会员，无法把此项目纳入计划。')
                } else if (resVip === "体验会员") {
                  that.$message.error(
                    "您还不是正式会员，无法把此项目纳入计划。"
                  );
                } else {
                  if (res.data.code === 200) {
                    if (res.data.code === 505) {
                      that.$message.error("已超过每月最大纳入计划次数。");
                    } else {
                      that.getUserDeclarePlan();
                      if (res.data.data === "纳入成功") {
                        that.visiblePlan = true;
                        // that.dialogVisiblePermission = !that.dialogVisiblePermission
                        // that.typeDialog = 'Includ'
                      } else {
                        that.$message({
                          message: res.data.data,
                          type: "success",
                        });
                      }
                    }
                  }
                }
              });
            })
            .catch(() => {});
        } else {
          // this.$message.error("请先登录再进行操作");
          that.dialogVisiblePermission = !this.dialogVisiblePermission;
          that.typeDialog = "Login";
        }
      });
    },
    handleToggle() {
      this.dialogVisiblePermission = !this.dialogVisiblePermission;
    },
    handlePermission(type) {
      if (type === "Vip") {
        this.dialogVisiblePermission = !this.dialogVisiblePermission;
        console.log(this.dialogVisiblePermission);
        this.$message({
          message: "已向客服发送请求成为协会会员通知",
          type: "success",
        });
      } else if (type === "Login") {
        this.$router.push({ name: "login" });
        this.dialogVisiblePermission = !this.dialogVisiblePermission;
      }
    },
    copyUrl(url) {
      var domUrl = document.createElement("input");
      domUrl.value = url;
      domUrl.id = "creatDom";
      document.body.appendChild(domUrl);
      domUrl.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      const creatDom = document.getElementById("creatDom");
      creatDom.parentNode.removeChild(creatDom);
      this.$message({
        showClose: true,
        message: "已复制好，可贴粘。",
        type: "success",
      });
    },
    handleCopy() {
      this.copyUrl(this.tableData.subtitle);
    },
  },
  watch: {
    input() {
      this.getUserDeclarePlan();
      if(this.showVipCom)return
      this.getProjectDetails();
    },
  },
};
</script>

<style lang="less" scoped>
.jiedu {
  .content {
    position: relative;
    padding-top: 70px;
    
    .tit {
      margin: 65px 0 20px 0;
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 15px;
      border-bottom: 1px solid #e5e5e5;
    }

    .title {
      display: flex;

      div:nth-child(1) {
        flex: 1;
        font-size: 24px;
      }
    }

    & > div:nth-child(2) {
      margin-top: 18px;
      height: 24px;
      line-height: 24px;

      span {
        font-size: 14px;
        margin-right: 20px;
      }

      .tags {
        color: #fee5d6;
        padding: 2.5px 8px;
        background-color: #f6841f;
        border-radius: 2px;
        box-sizing: border-box;
      }
    }

    /deep/.el-table {
      z-index: 0;
    }
  }
}

.notInclude {
  margin-right: 45px;
  padding: 7px 13px;
  font-size: 14px;
  align-self: center;
  color: #156ED0;
  border: 1px solid #156ED0;
  // background-color: #156ED0;
  // color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.include {
  margin-right: 47px;
  padding: 7px 13px;
  font-size: 14px;
  align-self: center;
  background-color: #ccc;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.jietu2 {
  background-color: rgb(248, 248, 248);
}

.copy-sub {
  display: inline;
  cursor: pointer;
}

.jietu2 > div {
  margin-bottom: 10px;
  position: relative;
  top: 30px;
  left: 20px;

  span {
    font-size: 14px;
    margin-right: 20px;
  }

  .tags {
    color: #fee5d6;
    padding: 2.5px 8px;
    background-color: #f6841f;
    border-radius: 2px;
    box-sizing: border-box;
  }
}

.fuchi {
  color: white;
  background-color: #fb9435;
  padding: 3px 8px;
  border-radius: 4px;
}

.jiezhi {
  color: red;
}

.btcolor {
  padding: 3px 5px;
  color: #fff;
  border-radius: 3px;
}

.dialog_text_color {
  color: rgba(51, 51, 51, 100);
  font-size: 14px;
  text-align: center;
  font-family: SourceHanSansSC-regular;
}

.dialog_content {
  /deep/.el-dialog__footer {
    text-align: center;
  }

  /deep/.el-button {
    width: 80%;
    height: 36px;
    border-radius: 3px;
    background-color: rgba(48, 120, 240, 100);
    color: rgba(255, 255, 255, 100);
    font-size: 14px;
    text-align: center;
    font-family: Microsoft Yahei;
  }
}

.dialog_content {
  /deep/.el-dialog__footer {
    text-align: center;
  }

  /deep/.el-button {
    width: 60%;
    height: 36px;
    border-radius: 3px;
    background-color: rgba(48, 120, 240, 100);
    color: rgba(255, 255, 255, 100);
    font-size: 14px;
    text-align: center;
    font-family: Microsoft Yahei;
  }
}
</style>
